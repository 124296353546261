<template>

<div class="timeline">

    <div class="inner">

        <div class="timeline-title">{{ item.title }}</div>

        <div class="timeline-body">

            <div class="timeline-body-head">

                <div>People</div>
                <div>Building & Events</div>

            </div>

            <div class="timeline-body-item" :class="{'has-image': entry.image}" :data-context="entry.context" v-for="(entry, index) in item.entries" :key="index">

                <div class="timeline-body-item-year">{{ entry.year }}</div>

                <div class="timeline-body-item-text" v-html="entry.text" />

                <img class="timeline-body-item-image" :src="entry.image" v-if="entry.image" />

            </div>

        </div>

    </div>

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.timeline {
    background-color: #FBF1E4;
    background-position: 50% 50%;
    padding-bottom: 30px;
    background-size: cover;
}

.timeline-title {
    color: #00583D;
    font-weight: 400;
    font-size: 35px;
    line-height: 40px;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    padding: 64px 0px 32px 0px;
}

.is-tablet .timeline-title {
    font-size: 28px;
    line-height: 32px;
}

.is-mobile .timeline-title {
    font-size: 20px;
    line-height: 26px;
}

.timeline-body {
    background-color: #fff;
    padding-bottom: 40px;
}

.is-mobile .timeline-body {
    margin: 0px 10px;
    padding: 20px 10px;
}

.timeline-body:before {
    content: '';
    display: block;
    left: 50%;
    top: 0px;
    bottom: 0px;
    width: 1px;
    background-color: #4BA574;
    position: absolute;
}

.is-mobile .timeline-body:before {
    left: 0px;
}

.timeline-body-head {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    color: #4BA574;
    font-size: 16px;
    padding: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin-bottom: 40px;
}

.is-tablet .timeline-body-head {
    margin-bottom: 0px;
}

.is-mobile .timeline-body-head {
    display: none;
}

.timeline-body-item {
    padding: 20px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
}

.timeline-body-item.has-image {
    min-height: 260px;
}

.timeline-body-item[data-context="1"] {
    align-items: flex-end;
    padding-right: calc(50% + 64px);
}

.timeline-body-item[data-context="2"] {
    align-items: flex-start;
    padding-left: calc(50% + 64px);
}

.is-mobile .timeline-body-item[data-context="1"],
.is-mobile .timeline-body-item[data-context="2"] {
    align-items: flex-start;
    padding: 10px;
}

.timeline-body-item-year {
    color: #4BA574;
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.timeline-body-item-year:before {
    display: block;
    content: '';
    position: absolute;
    width: 23px;
    height: 1px;
    background-color: #4BA574;
    top: 50%;
}

.timeline-body-item[data-context="1"] .timeline-body-item-year:before {
    right: -32px;
}

.timeline-body-item[data-context="2"] .timeline-body-item-year:before {
    left: -32px;
}

.is-mobile .timeline-body-item[data-context="1"] .timeline-body-item-year:before,
.is-mobile .timeline-body-item[data-context="2"] .timeline-body-item-year:before {
    right: auto;
    left: -20px;
    width: 10px;
}

.is-mobile .timeline-body-item-year:after {
    display: inline-block;
    padding: 0px 5px;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 16px;
    margin-left: 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

.is-mobile .timeline-body-item[data-context="1"] .timeline-body-item-year:after {
    background-color: #F0C994;
    content: 'People';
}

.is-mobile .timeline-body-item[data-context="2"] .timeline-body-item-year:after {
    background-color: #4BA574;
    content: 'Building & Events';
}

.timeline-body-item-text {
    color: #45464B;
    font-size: 16px;
    line-height: 28px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    max-width: 460px;
}

.is-tablet .timeline-body-item-text {
    font-size: 14px;
    line-height: 24px;
    max-width: 100%;
}

.is-mobile .timeline-body-item-text {
    font-size: 13px;
    line-height: 24px;
    max-width: 100%;
}

.is-desktop .timeline-body-item[data-context="1"] .timeline-body-item-text,
.is-tablet .timeline-body-item[data-context="1"] .timeline-body-item-text {
    text-align: right;
}

.timeline-body-item-text >>> a {
    color: #28406d;
    font-weight: 700;
}

.timeline-body-item-text >>> a:hover {
    text-decoration: underline;
}

.timeline-body-item-image {
    position: absolute;
    width: auto;
    height: 240px;
}

.timeline-body-item[data-context="1"] .timeline-body-item-image {
    left: calc(50% + 32px);
}

.timeline-body-item[data-context="2"] .timeline-body-item-image {
    right: calc(50% + 32px);
}

.is-mobile .timeline-body-item[data-context="1"] .timeline-body-item-image,
.is-mobile .timeline-body-item[data-context="2"] .timeline-body-item-image {
    right: auto;
    left: auto;
    width: 100%;
    height: auto;
    position: relative;
    margin: 20px 0px;
}

</style>
